<template>
  <div class="row books">
    <div class="col-md-12 mt-2">
      <div class="card">
        <h3 class="title text-center mt-4">Payment Gateway</h3>
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="row justify-content-end mb-2">
            <label for="" class="col-sm-2"> Search Payment <br /> </label>
            <div class="col-sm-2">
              <b-form-select
                v-model="status"
                :options="statusOptions"
                required
                @change="onStatusChange"
              ></b-form-select>
            </div>
            <div class="col-sm-5">
              <input
                type="text"
                class="form-control"
                placeholder="type name of payment gateway..."
                @input="debounceSearch"
              />
              <span v-if="typing"> <em> searching... </em> </span>
            </div>
            <router-link
              class="btn btn-primary mr-3"
              :to="{ path: '/settings/payment/create' }"
            >
              <i class="fa fa-plus"></i>
              Add Payment
            </router-link>
            <button
              class="btn btn-sm btn-dark"
              @click="actionGetPaymentGateway"
            >
              <i class="fa fa-refresh"></i>
            </button>
            <label for="" class="col-sm-auto">Count </label>
            <strong class="col-sm-auto">{{ totalRows }}</strong>
          </div>
          <br />
          <b-table
            sticky-header="500px"
            :items="payments"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            hover
            responsive="xl"
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}
            </template>
            <template #cell(name)="row">
              <router-link
                :to="{ path: '/settings/payment/edit/' + row.item.id }"
              >
                {{ row.item.name }}
              </router-link>
            </template>
            <template #cell(is_active)="row">
              <div>
                <span v-if="row.item.is_active" class="badge badge-success">
                  Active
                </span>
                <span v-else class="badge badge-danger">Inactive</span>
              </div>
            </template>
            <template #cell(info)="row"> {{ row.item.formula }} </template>
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'Payments',
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      message: '',
      typing: null,
      fields: [
        { key: 'No', label: 'No' },
        { key: 'name', label: 'Name' },
        { key: 'is_active', label: 'Status' },
        { key: 'payment_group', label: 'Group' },
        {
          key: 'merchant_code',
          label: 'Merchant Code',
          thStyle: { minWidth: '140px' },
          tdStyle: { minWidth: '140px' },
        },
        {
          key: 'minimal_amount',
          label: 'Minimal Amount',
          thClass: 'text-right',
          tdClass: 'text-right',
          thStyle: { minWidth: '140px' },
          tdStyle: { minWidth: '140px' },
        },
        { key: 'info', label: 'Info' },
      ],
      status: '',
      statusOptions: [
        { value: '', text: 'Status' },
        { value: 'true', text: 'Active' },
        { value: 'false', text: 'In Active' },
      ],
    };
  },
  watch: {
    currentPage: function() {
      this.actionGetPaymentGateway();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Payment Gateway | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.payments.isLoading,
      isError: (state) => state.payments.isError,
      totalRows: (state) => state.payments.totalRows,
      payments: (state) => state.payments.items,
    }),
  },
  mounted() {
    this.actionGetPaymentGateway();
  },
  methods: {
    ...mapActions('payments', [
      'fetchPayment',
      'searchPayments',
      'fetchPaymentById',
    ]),
    actionGetPaymentGateway() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
        status: this.status,
      };
      this.fetchPayment(payload);
    },
    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == 'USD') {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return '0';
    },

    debounceSearch(event) {
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        this.fetchPayment({
          name: this.message,
          page: 1,
          limit: this.perPage,
          status: this.status,
        });
      }, 600);
    },

    onStatusChange() {
      let payload = {
        name: this.message,
        status: this.status,
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchPayment(payload);
    },
  },
};
</script>
